import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Table, Row, Col, Space, message } from "antd";
import { UpOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons";

import axios from "axios";
import moment from "moment";

import LocationInput from "../forms/LocationInput";
import { useBreakpoint } from "../../hooks/useWindowSize";
import config from "../../config";
import CandidatePreview from "../candidate/CandidatePreview";

const Elections = () => {
  const [electionsData, setElectionsData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(true);
  const [localState, setLocalState] = useState("");
  const [state, setState] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const { mobileView } = useBreakpoint();
  // MOCK DATA
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const isAdmin = user.role === "admin";

  useEffect(() => {
    getCurrentLocation();
  }, []); // eslint-disable-line

  async function getStateFromLatLng(lat, lng) {
    const { data: res } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );
    const address = res.results[0].address_components.filter((v) =>
      v.types.includes("administrative_area_level_1")
    );

    const addressState = address[0].long_name;
    return addressState;
  }

  function getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setIsLoading(true);
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          const addressState = await getStateFromLatLng(pos.lat, pos.lng);

          setLocalState(addressState);
          setState(addressState);
          await getElectionsData();
          setIsLoading(false);
        },
        () => {
          // handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    }
  }

  async function getElectionsData() {
    try {
      let { data } = await axios.get(`${config.ELECTIONS}/all`);
      let elections = await Promise.all(
        data.map(async (v) => {
          let startDate = moment(v.startDate).format("MMM DD, YYYY");
          let endDate = moment(v.endDate).format("MMM DD, YYYY");
          let formattedDate = `${startDate} - ${endDate}`;

          let candidatesWithData =
            v?.candidates?.length > 0
              ? await getCandidatesData(v.candidates)
              : [];

          return {
            ...v,
            key: v._id,
            candidates: candidatesWithData,
            date: formattedDate,
          };
        })
      );

      setElectionsData(elections);
      setIsLoading(false);
    } catch (err) {
      message.error("Something is wrong!");
      console.log(err);
    }
  }


  async function getElections() {
    //Run a endpoint to grab all candidates with profileType: new candidate
    //Once candidates are retrieved run a map function on each officeSelections to get district, state and political division, candidate id and add them to a new list
    //New elements should only be added if district, state and pd are different otherwise just add candidates to the same array 
    //                                                                                                                                                                                                                                                                          

  }

  async function getCandidatesData(ids) {
    if (ids?.length === 0) return [];
    let headers = {
      ids: JSON.stringify(ids),
      token: localStorage.getItem("access_token"),
    };
    const { data: candidateResultData } = await axios.get(
      `${config.CANDIDATES}/candidates/find-by-ids`,
      { headers }
    );

    return candidateResultData;
  }

  useEffect(() => {
    getElectionsData();
  }, []); // eslint-disable-line

  const tableColumns = [
    {
      title: "Date",
      dataIndex: "date",
      width: 300,
    },
    {
      title: "State",
      dataIndex: "state",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "name",
    },
  ];

  if (isAdmin) {
    tableColumns.push({
      title: "",
      dataIndex: "operation",
      fixed: mobileView ? "right" : null,
      width: mobileView ? 60 : null,
      render: (_, b) => (
        <Space size="middle">
          <Link to={`/elections/edit/${b._id}`} className="election-action">
            <span>Edit</span>
          </Link>
          {/* <Popconfirm title="Are you sure?" onConfirm={() => {}}>
            <span
              className="election-action"
              style={{
                cursor: "pointer",
              }}
            >
              Delete
            </span>
          </Popconfirm> */}
        </Space>
      ),
    });
  }

  function isRowExpanded({ expanded, onExpand, record }) {
    const showIcon = record?.candidates.length > 0;
    if (!showIcon) return;
    return showIcon && expanded ? (
      <Button
        size="small"
        icon={<UpOutlined />}
        onClick={(e) => onExpand(record, e)}
      />
    ) : (
      <Button
        size="small"
        icon={<DownOutlined />}
        onClick={(e) => onExpand(record, e)}
      />
    );
  }

  async function findCandidatesByLocation(lat, lng) {
    setIsLoading(true);
    const addressState = await getStateFromLatLng(lat, lng);
    setState(addressState);
    setIsLoading(false);
  }

  let filteredData = electionsData;
  if (activeFilter === "close") {
    filteredData = electionsData.filter((v) => v.state.includes(localState));
  } else if (locationValue) {
    filteredData = electionsData.filter((v) => v.state.includes(state));
  }

  return (
    <div className="elections-container">
      {isAdmin && (
        <Link to="/elections/new" className="news-cta--btn-container">
          <Button
            block
            className="btn-bordered"
            icon={<PlusOutlined />}
            size="large"
          >
            Add Elections
          </Button>
        </Link>
      )}
      <div className="election-action-btns" style={{ marginBottom: 16 }}>
        <Button
          onClick={() => {
            setLocationValue("");
            setActiveFilter("close");
          }}
          type={activeFilter === "close" && "primary"}
          style={{ marginRight: 8 }}
        >
          Near me
        </Button>
        <Button
          onClick={() => {
            setActiveFilter("all");
            setLocationValue("");
          }}
          type={activeFilter === "all" && "primary"}
        >
          All
        </Button>
      </div>
      {activeFilter === "all" && (
        <div className="find-candidates-title-container">
          <h3>Find Your Elections</h3>
          <LocationInput
            id="state"
            stateOnly
            value={locationValue}
            onChange={(v) => setLocationValue(v)}
            getLangLng={findCandidatesByLocation}
          />
        </div>
      )}
      <Table
        className="elections-table"
        size="small"
        columns={tableColumns}
        loading={isLoading}
        dataSource={filteredData}
        scroll={{ x: 768 }}
        pagination={{
          defaultPageSize: 5,
        }}
        expandable={{
          rowExpandable: (record) => record?.candidates.length > 0,
          expandIcon: isRowExpanded,
          expandedRowRender: (record) => (
            <Row>
              {record.candidates.map((candidate, idx) => (
                <Col
                  key={candidate + idx}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                  className="ant-card-grid-hoverable"
                >
                  <Link
                    key={`candidate ${idx}`}
                    to={{
                      pathname: `/candidate/${candidate["_id"]}`,
                      state: {
                        data: candidate,
                      },
                    }}
                  >
                    <CandidatePreview
                      details={candidate}
                      buttonVisibility={false}
                    />
                  </Link>
                </Col>
              ))}
            </Row>
          ),
        }}
      />
    </div>
  );
};

export default Elections;
