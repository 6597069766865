import React, {useState} from 'react';
import {AutoComplete,Input,message} from 'antd'
import { useHistory } from "react-router-dom";
import config from "../../config.js";
import axios from "axios";

const {Option, OptGroup} = AutoComplete;
const {Search} = Input;


const AdvancedSearchNav = () => {
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    let history = useHistory();

    //Handles searching of candidates, news and services collections based on user selections and input. 
    const handleSearch = async (value) => {
        setSearchValue(value);
        setOptions([]);
        if (value) {
            try {
                // const[candidateResponses,newsResponses, servicesResponses] = await Promise.all([
                //     axios.get(`${config.CANDIDATES}/candidates/search?search-text=${value}&limit=100`),
                //     // axios.get(`${config.SERVICES}/services/search?search-text=${value}&limit=100`),
                //     axios.get(`${config.BLOGS}/blogs/all`)
                // ]);

                const response = await axios.get(`${config.CANDIDATES}/candidates/search?search-text=${value}&limit=100`);

                console.log(response)

                const news = response.data.blogs;
                const candidates = response.data.candidates;
                // const services = response.data.services;

                console.log(response.data.blogs);
                console.log(response.data.candidates);

                //set limit of 3 to display in the suggested dropdown
                const limit = 3;

                //Flatten the news structure 
                const flattenedNews = news.flatMap(category => category.blogs.map(item => ({value: item.title})));

                const flattenedCandidates = candidates.flatMap(category => category.data.map(item => ({value: item.bio.name})));

                // const flattenedServices = services.flatMap(category => category.services.map(item => ({value: item.organization_name })));

                const formattedOptions = [
                    {label: "News", options: flattenedNews.slice(0,limit)},
                    {label: "Candidates", options: flattenedCandidates.slice(0,limit)}
                    // {label: "Services", options: flattenedServices.slice(0,limit)}
                ];

                setOptions(formattedOptions);
                console.log(formattedOptions);
                console.log(options);
                

            } catch(error) {
                message.error("Failed to load results");
            }
         } else {
                setOptions([]);
            }
        };


    //Handles the scenario where the user selects from the suggested options
    const handleSelect = async (value) => {
        console.log(value);
        if (value.length > 0) {
            history.push({
              pathname: "/search",
              search: `?query=${value}`,
              state: { detail: value },
            });
          }

    }


    //Handles the scenario where the user inputs their search value and hit enter or the search button

    const handleSearchButton = async (value) => {
        console.log(value);
        if (value.length > 0) {
            history.push({
              pathname: "/search",
              search: `?query=${value}`,
              state: { detail: value },
            });
          }
    }


    const renderTitle = (title) => (
        <span>
            {title}
        </span>

    )

    const renderOptions = () => {
        return options.map(group => (
            <OptGroup key={group.label} label= {renderTitle(group.label)}>
                {group.options.map(option => (
                    <Option key={option.value} value={option.value}>
                        {option.value}
                    </Option>
                ))}
            </OptGroup>
        ));
    };




    return (
        <AutoComplete
            style={{width: 300}}
            onSearch = {handleSearch}
            onSelect = {handleSelect}
            dropdownMatchSelectWidth={false}
            optionLabelProp="value"
            options = {options}
        >
            <Search
                size = "large"
                placeholder = "Search candidates,news and services"
                enterButton
                onSearch = {handleSearchButton}
                
            />
            {/* {renderOptions()} */}
        </AutoComplete>
    );

};
export default AdvancedSearchNav;