import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  message,
  Modal,
  Select
} from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import SingleImageUploader from "../../shared/imageUploaders/singleImage/SingleImageUploader";
import axios from "axios";
import moment from "moment";

// Components
import PoliticalParty from "./components/PoliticalParty";
import Religion from "./components/Religion";
import Position from "./components/Position";
import OfficeSelections from "./components/office/OfficeSelections";
import OfficeSelectionReformed from "./components/office/OfficeSelectionReformed";
import Vote from "./components/Vote";
import Contribution from "./components/Contribution";
import Committee from "./components/Committee";
import FamilyNew from "./components/FamilyNew";
import Education from "./components/Education";
import SocialMedia from "./components/SocialMedia";
import OfficeContacts from "./components/OfficeContacts";
import CustomInput from "../../../components/shared/onboarding/CustomInput";
import ProfessionalExperience from "./components/ProfessionalExperience";

// Google Place Autocomplete API -- START
let cityAutoComplete;
let addressAutoComplete;

function handleScriptLoad(
  setCityQuery,
  cityId,
  setOfficeAddressQuery,
  addressId
) {
  cityAutoComplete = new window.google.maps.places.Autocomplete(cityId, {
    types: ["(cities)"],
    componentRestrictions: { country: "us" },
  });
  cityAutoComplete.setFields(["address_components", "formatted_address"]);
  cityAutoComplete.addListener("place_changed", () =>
    handleCityPlaceSelect(setCityQuery)
  );

  addressAutoComplete = new window.google.maps.places.Autocomplete(addressId, {
    types: ["address"],
    componentRestrictions: { country: "us" },
  });
  addressAutoComplete.setFields(["address_components", "formatted_address"]);
  addressAutoComplete.addListener("place_changed", () =>
    handleAddressPlaceSelect(setOfficeAddressQuery)
  );
}

async function handleCityPlaceSelect(updateQuery) {
  const cityObject = cityAutoComplete.getPlace();
  const query = cityObject.formatted_address;
  updateQuery(query);
}

async function handleAddressPlaceSelect(updateQuery) {
  const addressObject = addressAutoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
}
// Google Place Autocomplete API -- END

const EditCandidateForm = (props) => {
  const [formValues, setFormValues] = useState();
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  // Profile Picture
  const [logoUrl, setLogoUrl] = useState(
    props.initialData ? props.initialData.bio.profileImage : ""
  );

  // Home City & Office Address
  const [cityQuery, setCityQuery] = useState(
    props?.initialData?.bio?.personal?.homeCity
      ? props.initialData.bio.personal.homeCity
      : ""
  );
  const [officeAddressQuery, setOfficeAddressQuery] = useState(
    props?.initialData?.bio?.contact?.address
      ? props.initialData.bio.contact.address
      : ""
  );

  useEffect(() => {
    handleScriptLoad(
      setCityQuery,
      document.getElementById("city-place-autocomplete"),
      setOfficeAddressQuery,
      document.getElementById("address-place-autocomplete")
    );
    loadInitialData();
  }, []); // eslint-disable-line

  const loadInitialData = async () => {
    if (!props.initialData) return;
    try {
      if (props.initialData.bio.name) {
        form.setFieldsValue({ name: props.initialData.bio.name });
      }
      if (props.initialData.bio.personal.birthdate) {
        let birthDate = moment(
          props.initialData.bio.personal.birthdate,
          "YYYY-MM-DD"
        );

        form.setFieldsValue({
          birthdate: birthDate,
        });
      }

      if (props.initialData.bio.personal.contact) {
        form.setFieldsValue({
          contact: props.initialData.bio.personal.contact,
        });
      }
      if (props.initialData.bio.office.ballot) {
        form.setFieldsValue({
          ballotState: props.initialData.bio.office.ballot.state,
          ballotDivision: props.initialData.bio.office.ballot.divisionName,
          ballotOffice: props.initialData.bio.office.ballot.title,
          ballotTerm: props.initialData.bio.office.ballot.term,
        });
      }

      if(props.initialData.bio.office.profileType) {
        form.setFieldsValue({
          profileType: props.initialData.bio.office.profileType
        })
      }

      if (props.initialData.bio.office.incumbent) {
        form.setFieldsValue({
          incumbentState: props.initialData.bio.office.incumbent.state,
          incumbentDivision:
            props.initialData.bio.office.incumbent.divisionName,
          incumbentOffice: props.initialData.bio.office.incumbent.title,
          incumbentTerm: props.initialData.bio.office.incumbent.term,
        });
      }

      if (props.initialData.bio.office.party) {
        form.setFieldsValue({
          politicalParty: props.initialData.bio.office.party,
        });
      }

      if (props.initialData.bio.contact.phone) {
        form.setFieldsValue({
          phone: props.initialData.bio.contact.phone,
        });
      }
      if (props.initialData.bio.contact.email) {
        form.setFieldsValue({
          email: props.initialData.bio.contact.email,
        });
      }

      if (props.initialData.bio.contact.fax) {
        form.setFieldsValue({
          fax: props.initialData.bio.contact.fax,
        });
      }

      if (props.initialData.bio.personal.religion) {
        form.setFieldsValue({
          religion: props.initialData.bio.personal.religion,
        });
      }

      if (props.initialData.bio.personal.family) {
        if(props.initialData.bio.personal.family.spouses) {
          let spouses = [];
          props.initialData.bio.personal.family.spouses.forEach((spouse) => {
            spouses.push({
              spouse: spouse.Name,
              noOfChildren: spouse.childrens
            })
          })
          form.setFieldsValue({
            spouses,
          })
        
        }
        form.setFieldsValue({
          father: props.initialData.bio.personal.family.father,
          mother: props.initialData.bio.personal.family.mother,
        });
      }

      if (props.initialData.bio.personal.education) {
        form.setFieldsValue({
          college: props.initialData.bio.personal.education.college,
          highschool: props.initialData.bio.personal.education.highschool,
        });
      }

      // if (props.initialData.bio.political.experience) {
      //   form.setFieldsValue({
      //     politicalExperience: props.initialData.bio.political.experience,
      //   });
      // }

      // if (props.initialData.bio.professional.experience) {
      //   form.setFieldsValue({
      //     careerExperience: props.initialData.bio.professional.experience,
      //   });
      // }


      if (props.initialData.bio.other.experience) {
        form.setFieldsValue({
          otherMembership: props.initialData.bio.other.experience,
        });
      }

      if (props.initialData.positions) {
        let positions = [];
        props.initialData.positions.forEach((position) => {
          positions.push({
            category: position.topic,
            detail: position.position,
          });
        });
        form.setFieldsValue({
          positions,
        });
      }

      if (props.initialData.legislations) {
        let legislations = [];
        props.initialData.legislations.forEach((legislation) => {
          legislations.push({
            bill: legislation.bill,
            billName: legislation.billName,
            sponsor: legislation.sponsor,
            summary: legislation.summary,
            vote: legislation.vote,
          });
        });
        form.setFieldsValue({
          legislations,
        });
      }

      if (props.initialData.contributions) {
        let contributions = [];
        props.initialData.contributions.forEach((contribution) => {
          contributions.push({
            industry: contribution.industry,
            name: contribution.name,
            amount: contribution.amount,
            yearOfContribution: contribution.yearOfContribution,
          });
        });
        form.setFieldsValue({
          contributions,
        });
      }

      if (props.initialData.officeSelections) {
        let officeSelections = [];
        props.initialData.officeSelections.forEach((officeSelection)=> {
          officeSelections.push({
            politicalDivision: officeSelection.politicalDivision, 
            title: officeSelection.title,
            position: officeSelection.position,
            district: officeSelection.district,
            state: officeSelection.state,
            division: officeSelection.city,
            office: officeSelection.office,
            term: officeSelection.term
          })
        })
        form.setFieldsValue({
          officeSelections,
        });
      }

      // if(props.initialData.bio.personal.family.spouses) {
      //   let spouses = [];
      //   props.initialData.bio.personal.family.spouses.forEach((spouse) => {
      //     spouses.push({
      //       Name: spouse.spouse,
      //       childrens: spouse.noOfChildren
      //     })
      //   })
      //   form.setFieldsValue({
      //     spouses,
      //   });
      // }

      if(props.initialData.professionalExperiences) {
        let professionalExperiences = [];
        props.initialData.professionalExperiences.forEach((professionalExperience)=> {
          professionalExperiences.push({
            title: professionalExperience.title,
            startDate: moment(professionalExperience.startDate, "YYYY-MM-DD"),
            endDate: moment(professionalExperience.endDate, "YYYY-MM-DD")
          })
        })
        form.setFieldsValue({
          professionalExperiences,
        });
      }

      if (props.initialData.committees) {
        let committees = [];
        props.initialData.committees.forEach((committee) => {
          committees.push({
            committeeDate: moment(committee.date, "YYYY-MM-DD"),
            description: committee.description
          });
        });
        form.setFieldsValue({
          committees,
        });
      }

      if (props.initialData.bio.social.website) {
        form.setFieldsValue({
          website: props.initialData.bio.social.website,
        });
      }

      if (props.initialData.bio.social.facebook) {
        form.setFieldsValue({
          facebook: props.initialData.bio.social.facebook,
        });
      }

      if (props.initialData.bio.social.instagram) {
        form.setFieldsValue({
          instagram: props.initialData.bio.social.instagram,
        });
      }

      if (props.initialData.bio.social.twitter) {
        form.setFieldsValue({
          twitter: props.initialData.bio.social.twitter,
        });
      }

      if (props.initialData.bio.social.youtube) {
        form.setFieldsValue({
          youtube: props.initialData.bio.social.youtube,
        });
      }

      setInitialValues(form.getFieldValue());
    } catch (err) {
      message.error("There was an error loading candidate data");
    }
  };

  // Office Selection
  const [form] = Form.useForm();
  const [divisionIds, setDivisionIds] = useState({
    incumbent: undefined,
    ballot: undefined,
  });

  // Birthdate
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];


  //Profile Type

  const[profileType, setProfileType] = useState("");

  //Navigation - Cancel Button
  const history = useHistory();
  const backToAccountTypesOptions = () => {
    props.cancelModal(false);
  };

  //loading
  const [loading, setLoading] = useState(false);

  // Submission
  const onFinish = (values) => {
    // if (values.birthdate !== undefined) {
    //   values.birthdate = values.birthdate.format("DD/MM/YYYY");
    // }

    if (officeAddressQuery) {
      values.address = officeAddressQuery;
    }
    if (cityQuery) {
      values.homeCity = cityQuery;
    }

    const data = {
      bio: {
        rating: {
          total: props.initialData.bio.rating.total,
          stars: props.initialData.bio.rating.stars,
        },
        profileImage: logoUrl,
        name: values.name,
        social: {
          website: values.website,
          facebook: values.facebook,
          twitter: values.twitter,
          instagram: values.instagram,
          youtube: values.youtube,
          podcasts: values.podcasts,
        },
        general: {
          comments: props.initialData.bio.general.comments,
        },
        office: {
          // ballot: {
          //   division: divisionIds.ballot,
          //   state: values.ballotState,
          //   divisionName: values.ballotDivision,
          //   title: values.ballotOffice,
          //   term: values.ballotTerm,
          // },
          // incumbent: {
          //   division: divisionIds.incumbent,
          //   state: values.incumbentState,
          //   divisionName: values.incumbentDivision,
          //   title: values.incumbentOffice,
          //   term: values.incumbentTerm,
          // },
          party: values.politicalParty,
          profileType: values.profileType
        },
        contact: {
          phone: values.phone,
          fax: values.fax,
          address: values.address,
          email: values.email,
        },
        personal: {
          birthdate: values.birthdate,
          contact: values.contact,
          homeCity: values.homeCity,
          family: {
            spouses:[],
            childrens: values.childrens,
            father: values.father,
            mother: values.mother,
          },
          education: {
            college: values.college,
            highschool: values.highschool,
          },
          religion: values.religion,
          comments: props.initialData.bio.personal.comments,
        },
        // political: {
        //   experience: values.politicalExperience,
        //   comments: props.initialData.bio.political.comments,
        // },
        // professional: {
        //   experience: values.careerExperience,
        //   comments: props.initialData.bio.professional.comments,
        // },
       
        other: {
          experience: values.otherMembership,
          comments: props.initialData.bio.other.comments,
        },
      },
      positions: [],
      legislations: [],
      contributions: [],
      committees: [],
      officeSelections: [],
      professionalExperiences:[],
      questions: props.initialData.questions,
    };

    // // if ballot info is not modified
    // if (data.bio.office.ballot.division === undefined) {
    //   data.bio.office.ballot = props.initialData.bio.office.ballot;
    // }
    // // if incumbent info is not modified
    // if (data.bio.office.incumbent.division === undefined) {
    //   data.bio.office.incumbent = props.initialData.bio.office.incumbent;
    // }

    if (values.positions[0]) {
      values.positions.map((position) => {
        return data.positions.push({
          topic: position.category,
          position: position.detail,
          comments: [],
        });
      });
    }

    if (values.legislations[0]) {
      values.legislations.map((legislation) => {
        return data.legislations.push({
          bill: legislation.bill,
          billName: legislation.billName,
          sponsor: legislation.sponsor,
          summary: legislation.summary,
          vote: legislation.vote,
        });
      });
    }

    if (values.contributions[0]) {
      values.contributions.map((contribution) => {
        return data.contributions.push({
          industry: contribution.industry,
          name: contribution.name,
          amount: contribution.amount,
          yearOfContribution: contribution.yearOfContribution,
        });
      });
    }

    if (values.committees[0]) {
      values.committees.map((committee) => {
        return data.committees.push({
          date: committee.date,
          description: committee.description
        });
      });
    }

    if (values.officeSelections[0]){
      values.officeSelections.map((officeSelection) => {
        return data.officeSelections.push({
          politicalDivision: officeSelection.politicalDivision, 
          title: officeSelection.title,
          position: officeSelection.position,
          district: officeSelection.district,
          state: officeSelection.state,
          city: officeSelection.division,
          office: officeSelection.office,
          term: officeSelection.term,
        })

      })
    }
    if(values.professionalExperiences !== undefined){
      values.professionalExperiences.map((professionalExperience) => {
        return data.professionalExperiences.push({
          title: professionalExperience.title,
          startDate: professionalExperience.startDate,
          endDate: professionalExperience.endDate
        })
      })
    }

    if(values.spouses !== undefined) {
      values.spouses.map((spouse) => {
        return data.bio.personal.family.spouses.push({
          Name: spouse.spouse,
          childrens: spouse.noOfChildren
        })
      })
    }

    console.log(data);
    setLoading(true);

    return axios
      .patch(
        `${config.CANDIDATES}/candidates/update`,
        {},
        {
          headers: {
            candidate_id: props.initialData._id,
            token: localStorage.getItem("access_token"),
            doc: JSON.stringify(data),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (JSON.parse(localStorage.getItem("user")).role !== "admin") {
            if (
              logoUrl !== props.initialData.bio.profileImage ||
              values.name !== props.initialData.bio.name
            ) {
              const doc = JSON.stringify({
                nickname: values.name,
                picture:
                  logoUrl === ""
                    ? "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png"
                    : logoUrl,
              });
              return axios
                .patch(
                  `${config.USERS}/users`,
                  {},
                  {
                    headers: {
                      token: localStorage.getItem("access_token"),
                      doc: encodeURIComponent(doc),
                    },
                  }
                )
                .then((response) => {
                  setLoading(false);
                  if (response.status === 200) {
                    let user = JSON.parse(localStorage.getItem("user"));
                    user.nickname = values.name;
                    user.picture = logoUrl;
                    localStorage.setItem("user", JSON.stringify(user));
                    setLoading(false);
                    Modal.success({
                      content: "Your profile has been updated.",
                    });
                    history.push("/");
                  } else {
                    message.error(
                      "Something went wrong, make sure you are connected to the internet."
                    );
                  }
                });
            }
            setLoading(false);
            Modal.success({
              content: "Your profile has been updated.",
            });
            history.push("/");
          } else {
            setLoading(false);
            Modal.success({
              content: "Candidate has been updated.",
            });
            history.push("/");
          }
        } else {
          message.error(
            "Something went wrong, make sure you are connected to the internet."
          );
        }
      });
  };

  return (
    <Form
      name="candidateForm"
      className="accountForm"
      onFinish={onFinish}
      initialValues={{
        positions: [""],
        legislations: [""],
        contributions: [""],
        committees: [""]
      }}
      layout="vertical"
      form={form}
      onFieldsChange={() => {
        const changed = form.getFieldsValue();
        setFormValues(changed);
        setDisableSubmitBtn(
          JSON.stringify(changed) === JSON.stringify(initialValues)
        );
      }}
    >
      <Row gutter={[12]}>
        <Col span={24} className="candidate-edit-form-upload">
          <SingleImageUploader
            handleImageUpload={(url) => setLogoUrl(url)}
            imagePreviewUrl={logoUrl}
            typeKey={"user"}
            imageOptional={false}
            layout={"logo"}
          />
        </Col>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter your full name",
              },
            ]}
          >
            <Input
              placeholder="enter full name"
              onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item label="Contact Information" name="contact">
            <CustomInput
              // form={this.props.form}
              type="tel"
              placeholder="enter contact number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter your contact information",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item label="Date of Birth" name="birthdate" >
            <DatePicker
              style={{ width: "100%" }}
              format={dateFormatList}
              popupStyle={{ color: "black" }}
              placeholder="select a date (dd/mm/yyyy)"
            />
          </Form.Item>
        </Col>
        <Col>
        <Form.Item label="Profile type" name="profileType" rules={[
          {
            required: true, 
            message: "Please select your profile type"

          }
        ]}>
          <Select 
            showSearch
            placeholder="select profile type"
            onChange={(value) => {setProfileType(value)}}
            options={[
              {
                value: "new candidate",
                label: "new candidate"
              },
              {
                value: "candidate",
                label: "incumbent"
              },
              {
                value: "elected official",
                label: "elected official"
              }
            ]}
          />
        </Form.Item>
        </Col>
        <PoliticalParty />
        {/* <OfficeSelections
          form={form}
          type="ballot"
          setDivisionIds={setDivisionIds}
          divisionIds={divisionIds}
        />
        <OfficeSelections
          form={form}
          type="incumbent"
          setDivisionIds={setDivisionIds}
          divisionIds={divisionIds}
        /> */}

        <OfficeSelectionReformed
          form={form}
          type="ballot"
          setDivisionIds= {setDivisionIds}
          divisionIds={divisionIds}
        />

        <Col span={24}>
          <OfficeContacts
            setOfficeAddressQuery={
              setOfficeAddressQuery
            }
            officeAddressQuery={officeAddressQuery}
          />
        </Col>
        <Position />
        <Vote formValues={formValues} />
        <Contribution formValues={formValues} />

        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item label="Residence(s)">
            <Input
              suffix={<EnvironmentOutlined />}
              id="city-place-autocomplete"
              type="text"
              placeholder="search by city and state"
              onChange={(event) => setCityQuery(event.target.value)}
              value={cityQuery}
              onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
            />
          </Form.Item>
        </Col>
        <Religion />
        <Col span={12} />
        <Col span={24}>
          <FamilyNew formValues={formValues} />
        </Col>
        <Col span={24}>
          <Education />
        </Col>
        <Col span={24}>
          <ProfessionalExperience formValues={formValues}/>
        </Col>
        <Col span={24}>
          <Committee formValues={formValues}/>
        </Col>
        <Col span={24}>
          <SocialMedia />
        </Col>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Form.Item>
            <Button
              className="btn-bordered"
              onClick={backToAccountTypesOptions}
              style={{ margin: "0px 5px", width: "85px" }}
              type="text"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              className="btn-solid"
              htmlType="submit"
              style={{ margin: "0px 5px", width: "85px" }}
              type="text"
              loading={loading}
              disabled={loading || disableSubmitBtn}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditCandidateForm;
