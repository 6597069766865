import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import config from "../../config";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  BackTop,
  List,
  Progress,
  Button,
  Modal,
  message,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import QuestionAnswer from "../questionsandanswers/QuestionAnswer";
import CandidatePreview from "./CandidatePreview";
import Comments from "../comments/Comments";
import BioLeft from "./bio/BioLeft";
import BioMain from "./bio/BioMain";
import PlatformContent from "./bio/PlatformContent";
import UpdateContent from "./bio/UpdateContent";
import LegislationContent from "./bio/LegislationContent";
import ContributionContent from "./bio/ContributionContent";
import VimeoVideo from "../video/VimeoVideo";
import EditCandidateForm from "../forms/candidateForm/EditCandidateInfoForm";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const ACTIVE_CANDIDATE_TAB = "ACTIVE_CANDIDATE_TAB";

const styles = {
  card: {
    maxHeight: "100%",
  },
  cardBodyComments: {
    maxHeight: 400,
    overflow: "auto",
    marginTop: "5px",
  },
  cardBodyBio: {
    overflow: "auto",
  },
};

const Candidate = (props) => {
  const data = props.data;
  const [key, setKey] = useState(
    localStorage.getItem(ACTIVE_CANDIDATE_TAB) || "Bio"
  );
  const panelKey = "personal";
  const [currentAdvocacyKey, setCurrentAdvocacyKey] = useState(undefined);
  const [visible, setVisible] = useState(false);
  const [role, setRole] = useState("");
  const [status] = useState(data.archived);

  /*
    Permission control:
      candidate profile is draft / reject or archived is only able to access by admin
  */
  const history = useHistory();
  useEffect(() => {
    // setRole(JSON.parse(localStorage.getItem("user")).role)
    if (localStorage.getItem("user") !== null) {
      setRole(JSON.parse(localStorage.getItem("user")).role);
    }
    if (
      data.status === "draft" ||
      data.status === "reject" ||
      data.archived === true
    ) {
      if (
        !(
          localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role === "admin"
        )
      ) {
        history.push("/");
        message.error("You are not authorized to access this page");
      }
    }
  }, [data.status, data.archived, history]);

  //returns the new question object with replies without length inserted
  const insertFunction = (list, content) => {
    list.push(content);
    return list;
  };

  const handleArchive = (event, record) => {
    let candidateDoc = JSON.stringify({
      archived: !status,
    });
    const patchData = async () => {
      try {
        await axios.patch(
          `${config.CANDIDATES}/candidates/update`,
          {},
          {
            headers: {
              candidate_id: data._id,
              token: localStorage.getItem("access_token"),
              doc: encodeURIComponent(candidateDoc),
            },
          }
        );
        if (status === true) {
          message.warning("Candidate UnArchived");
          window.location.reload();
        } else {
          history.push("/");
          message.warning("Candidate Archived");
        }
      } catch (err) {
        message.error("Unable to update the candidate");
        console.error(err);
      }
    };
    return patchData();
  };

  const [commentList, setCommentList] = useState(data.positions);
  const [QAlist, setQAlist] = useState(data.questions || []);
  // Write code to fetch the comments with specific candidat_id and populate them QAlist
  const updateQA = (newContent, type, id) => {
    if (type === "question") {
      setQAlist((QAlist) => QAlist.concat(newContent));
    } else if (type === "reply") {
      let newArr = [...QAlist];
      insertFunction(newArr[id].answers, newContent);
      setQAlist((QAlist) => newArr);
    }
  };

  const cancelModal = () => {
    setVisible(false);
  };

  const tabList = [
    {
      key: "Videos",
      tab: "Videos",
    },
    {
      key: "Bio",
      tab: "Bio",
    },
    {
      key: "Platform",
      tab: "Advocacy",
    },
    {
      key: "Legislation",
      tab: "Voting History",
    },
    {
      key: "Update",
      tab: "NoCap Update",
    },
    {
      key: "Contributions",
      tab: "Contributions",
    },
  ];

  const contentList = {
    Bio: (
      <BioMain details={data} candidateId={data["_id"]} renderKey={panelKey} />
    ),
    Platform: (
      <PlatformContent
        details={data}
        candidateId={data["_id"]}
        setCurrentAdvocacyKey={setCurrentAdvocacyKey}
      />
    ),
    Update: (
      <UpdateContent
        details={data}
        candidateId={data["_id"]}
        blogData={props.blogData}
      />
    ),
    Legislation: (
      <LegislationContent details={data} candidateId={data["_id"]} />
    ),
    Contributions: (
      <ContributionContent details={data} candidateId={data["_id"]} />
    ),
    Videos: <VimeoVideo />,
  };

  function onCardTabChange(tabKey) {
    setKey(tabKey);
    localStorage.setItem(ACTIVE_CANDIDATE_TAB, tabKey);
  }

  function onArrowClick(event, type) {
    event.stopPropagation();
    const indexId = tabList.findIndex((v) => v.key === key);
    if (type === "left") {
      onCardTabChange(tabList[indexId - 1].key);
    } else {
      onCardTabChange(tabList[indexId + 1].key);
    }
  }
  const OperationsSlot = {
    left: (
      <Button
        onClick={(e) => onArrowClick(e, "left")}
        shape="circle"
        icon={<ArrowLeftOutlined />}
        style={{
          width: "50%",
        }}
      />
    ),
    right: (
      <Button
        onClick={(e) => onArrowClick(e, "right")}
        shape="circle"
        icon={<ArrowRightOutlined />}
        style={{
          width: "50%",
        }}
      />
    ),
  };

  return (
    <Row gutter={8}>
      <Col xs={0} sm={0} md={12} lg={8} xl={4}>
        <Card
          bordered={false}
          style={{
            marginTop: "2em",
          }}
        >
          <BioLeft details={data} />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={0} lg={0} xl={0}>
        <Card
          bordered={false}
          className="candidate-preview"
          style={{
            marginTop: "2em",
          }}
        >
          <Space
            direction="vertical"
            size="middle"
            style={{ textAlign: "center" }}
            align="center"
          >
            <CandidatePreview details={data} buttonVisibility={true} />
          </Space>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={16} xl={12}>
        {role === "admin" && (
          <div className="candidate-action-buttons">
            <Button
              // style={{ float: "right" }}
              className="candidate-action-buttons"
              icon={<EditOutlined />}
              onClick={() => setVisible(true)}
              style={{ marginLeft: 8, alignItems: "center" }}
            >
              {" "}
              Edit button
            </Button>
            <Modal
              title="Candidate Edit Form"
              centered
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
              width={1200}
            >
              <EditCandidateForm initialData={data} cancelModal={cancelModal} />
            </Modal>

            <div>
              <Button
                icon={status === false ? <DeleteOutlined /> : <UndoOutlined />}
                onClick={handleArchive}
              >
                {status === false ? "Archive" : "UnArchive"}
              </Button>
            </div>
          </div>
        )}

        <br />
        <br />
        <Card
          className="candidate-card"
          style={{
            margin: ".5em 0 .5em 0",
          }}
          bodyStyle={styles.cardBodyBio}
          tabList={tabList}
          tabBarExtraContent={OperationsSlot}
          activeTabKey={key}
          bordered={false}
          onTabChange={(key) => onCardTabChange(key)}
        >
          {contentList[key]}
        </Card>
      </Col>

      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
        {currentAdvocacyKey !== undefined && key === "Platform" ? (
          <>
            {/* <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                <CheckOutlined />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Text>Upvotes</Text>
              </Col>
              <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                <Progress
                  percent={80}
                  strokeColor="black"
                  size="small"
                  status="active"
                />
              </Col>
            </Row> */}
            {/* <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                <CloseOutlined />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Text>Downvotes</Text>
              </Col>
              <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                <Progress percent={20} strokeColor="black" size="small" />
              </Col>
            </Row> */}

            {/*  <Row> 
                  <Col>
                  </Col>
                </Row>
            */}
            <List.Item>
              <Card
                title={
                  <Space direction="vertical">
                    <Title style={{ color: "white" }} level={4}>
                      Fact Check
                    </Title>
                    <Text style={{ color: "#8c8c8c" }}>
                      {commentList[currentAdvocacyKey].topic}
                    </Text>
                  </Space>
                }
                bordered={false}
                style={{
                  margin: ".5em 0 .5em 0",
                  backgroundColor: "#1f1f1f",
                  height: 350,
                  width: 900,
                }}
                bodyStyle={styles.cardBodyComments}
              >
                <Comments
                  commentList={commentList}
                  renderKey={commentList[currentAdvocacyKey].topic}
                  currentAdvocacyKey={currentAdvocacyKey}
                  refreshCommentFunction={setCommentList}
                />
              </Card>
            </List.Item>
          </>
        ) : null}
        <Card
          title="Questions and Answers"
          className="candidate-questions"
          bordered={false}
        >
          <QuestionAnswer
            QAlist={QAlist}
            username={data["bio"]["name"]}
            candidateId={data["_id"]}
            refreshFunction={updateQA}
          />
        </Card>
      </Col>
      <BackTop />
    </Row>
  );
};

export default Candidate;
