// Libraries
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

// Components
import CategoryTags from "./CategoryTags";
import CandidateSearchAndTags from "../candidate/CandidateSearchAndTags";
import SingleImageUploader from "../shared/imageUploaders/singleImage/SingleImageUploader";
import QuillEditor from "./QuillEditor";
import LocationInput from "../forms/LocationInput";
import FactCheck from "./FactCheck";

// Ant Design Components
import { BackTop, Form, Input, message, Select } from "antd";

// Helpers & Config
import NoCapManifest from "../../nocap.json";
import config from "../../config";
import { CandidateContext } from "../../providers/CandidateContext";
import { NewsContext } from "../../providers/NewsContext";
import CreateForm from "../forms/CreateForm";

import DynamicCityStateDistrict from "../forms/candidateForm/components/DynamicCityStateDistrict"




const NewNewsPost = () => {
  const [loading, setLoading] = useState(false);
  // Scroll to top of page when viewed
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // States from context provider
  const { candidateTags, setCandidateTags } = useContext(CandidateContext);

  const { factCheckTags, setFactCheckTags } = useContext(NewsContext);



  useEffect(() => {
    setCandidateTags([]);

    // eslint-disable-next-line
  }, []);

  const [currentCategory, setCurrentCategory] = useState("");

  const [candidateData, setCandidateData] = useState({ candidates: [] });

  // Form States
  const [formData, setFormData] = useState({
    title: "",
    blogType: "",
    author: "",
    coverImage: "",
    body: "",
    videoUrl: "",
    editorial: "",
    state: "",
    district: ""
  });

  // Ant Design Title Form Field
  const [form] = Form.useForm();

  // Navigation and Buttons
  const history = useHistory();

  const handleCandidateSearch = async (search) => {
    try {
      const result = await axios.get(
        `${config.CANDIDATES}/candidates/search?search-text=${search}&limit=100`
      );

      setCandidateData(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClearCandidateSearch = () => {
    setCandidateData({ candidates: [] });
  };


  const handleFackCheckAddition = () => {

  }


  console.log(form.getFieldValue.title)
  console.log(form.getFieldValue("district"))
  console.log(form.getFieldValue("state"))
  console.log(form.getFieldValue.blogType)

  const handleCreateNewsPost = async (value) => {
    setLoading(true);
    let reducedTags = candidateTags.map((tag) => {
      return tag._id;
    });
    const newsPost = {
      ...formData,
      category: currentCategory,
      candidates: reducedTags,
      state: form.getFieldValue("state"),
      district: form.getFieldValue("district"),
      factCheck: factCheckTags
    };

    try {
      await axios.post(`${config.BLOGS}/blogs/new/`, newsPost, {
        headers: {
          token: localStorage.getItem("access_token"),
        },
      });
      message.success("Your blog post has been saved successfully!");
      console.log(newsPost);
      message.warning("Redirecting...", 1);
      history.goBack();

      setLoading(false);
    } catch (err) {
      console.log(err);
      message.warning("Your blog post wasn't saved‼️");
      setLoading(false);
    }
  };

  // Role-based render
  const user = JSON.parse(localStorage.getItem("user")) || {};

  if (user.role !== "admin") {
    return message.warning(
      `Please sign in as an administrator to create a new article.`
    );
  }

  return (
    <Form
      name="newsPostForm"
      form={form}
      layout="vertical"
      initialValues={{
        title: "",
        author: "",
        editorial: "",
        district: "",
        state: "",
      }}
      onFinish={handleCreateNewsPost}
    >
      <CreateForm submitLabel="Create Article" loading={loading}>
        <CategoryTags
            categories={NoCapManifest.news.newsCategories}
            width="95%"
            value={currentCategory}
            onChange={setCurrentCategory}
          />
        <SingleImageUploader
            handleImageUpload={(url) =>
              setFormData({ ...formData, coverImage: url })
            }
            imagePreviewUrl={formData.coverImage}
            typeKey={"newsCover"}
            imageOptional={false}
            layout={"newsCover"}
          />
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Title is required!" }]}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          label="Paid Blog" 
          name="blogType"
          rules={[
            {
              required: false,
              message: "Please select your blog type"
            }

          ]}
        >
          <Select
            showSearch
            placeholder = "select your blog type"
            onChange = {(value) => setFormData({...formData, blogType: value })}
            options = {[
              {
                value: "yes",
                label: "yes"
              }, 
              {
                value: "no", 
                label: "no"
              }
            ]}
          />
        </Form.Item>
        <Form.Item
          name="author"
          label="Author"
          onChange={(e) => setFormData({ ...formData, author: e.target.value })}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item label="City" name="city">
          <LocationInput
            id="city"
            cityOnly
            value={form.getFieldValue("city")}
          />
        </Form.Item>

        <Form.Item label="State" name="state">
          <LocationInput
            id="state"
            stateOnly
            value={form.getFieldValue("state")}
          />
        </Form.Item> */}

        <Form.Item>
          <DynamicCityStateDistrict 
            form={form}
          />
        </Form.Item>



        

        
        {/* {props.data.videoUrl ? (
              <div className="videoContainer">
                <div className="videoWrapper">
                  <iframe
                    src={props.data.videoUrl}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                  />
                </div>
              </div>
            ) : null} */}
        <QuillEditor
          value={formData.body}
          onChange={(html) => setFormData({ ...formData, body: html })}
        />

        <FactCheck/>
        
        <Form.Item name="editorial" label="Editorial/NoCap Commentary">
          <Input.TextArea />
        </Form.Item>

        <CandidateSearchAndTags
          candidateData={candidateData}
          onSearch={(value) => handleCandidateSearch(value)}
          onClick={handleClearCandidateSearch}
        />




      </CreateForm>
      <BackTop />
    </Form>
  );
};

export default NewNewsPost;
