import React, { Fragment, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Drawer,
  Button,
  Dropdown,
  Menu,
  Avatar,
  Divider,
  Space,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import SearchBar from "./AdvancedSearchNav";
import NoCapManifest from "../../nocap.json";

const TopNavBar = (props) => {
  const [drawer, setDrawer] = useState(false);
  const { isAuthenticated } = props.auth;

  const history = useHistory();
  const handleLogout = () => {
    props.auth.logout();
    history.push("/");
  };
  const handleDrawerClose = () => {
    setDrawer(false);
  };

  // Desktop
  let accountLinks = (
    <Space size="small">
      <NavLink to="/signup" style={{ fontWeight: "bold" }}>
        SIGN&nbsp;UP
      </NavLink>
      <Divider type="vertical" style={{ backgroundColor: "darkgrey" }} />
      <NavLink to="/login" style={{ fontWeight: "bold" }}>
        LOGIN
      </NavLink>
    </Space>
  );

  let menuLinks = NoCapManifest.pages.map((item, i) => {
    return (
      <NavLink key={item + i} to={item.path}>
        {item.title}
      </NavLink>
    );
  });

  // Mobile
  let mobileAccountLinks = (
    <Space direction="vertical" align="center">
      <NavLink
        onClick={() => handleDrawerClose()}
        to="/signup"
        style={{ fontWeight: "bold", fontSize: "1rem" }}
      >
        SIGN&nbsp;UP
      </NavLink>
      <NavLink
        onClick={() => handleDrawerClose()}
        to="/login"
        style={{ fontWeight: "bold", fontSize: "1rem" }}
      >
        LOGIN
      </NavLink>
    </Space>
  );

  let mobileMenuLinks = NoCapManifest.pages.map((item, i) => {
    return (
      <NavLink
        key={item + i}
        to={item.path}
        onClick={() => handleDrawerClose()}
      >
        {item.title}
      </NavLink>
    );
  });

  if (isAuthenticated()) {
    let user = JSON.parse(localStorage.getItem("user"));
    let username = user?.nickname ? user.nickname : user.name;
    const role = user.role;

    let dropDownMenu = (
      <Menu>
        {user.email_verified
          ? NoCapManifest.roles[`${role}`].topNavMenuItems.map((item) => {
              return (
                <Menu.Item key={item + item.name}>
                  <NavLink to={item.path}>{item.name}</NavLink>
                </Menu.Item>
              );
            })
          : null}
        {role !== "admin" && (
          <Menu.Item key="change-type">
            <NavLink to="change-type">Change account type</NavLink>
          </Menu.Item>
        )}
        <Menu.Item key="logout">
          <NavLink to="/" onClick={handleLogout}>
            Log&nbsp;out
          </NavLink>
        </Menu.Item>
      </Menu>
    );
    accountLinks = (
      <Space size="small">
        <Divider type="vertical" style={{ backgroundColor: "darkgrey" }} />
        Welcome,
        <Dropdown overlay={dropDownMenu}>
          <a
            className="ant-dropdown-link"
            href="/#"
            onClick={(e) => e.preventDefault()}
          >
            {username} <DownOutlined />
          </a>
        </Dropdown>
      </Space>
    );

    let userPicture =
      user.picture.startsWith("https://s.gravatar.com/avatar/") ||
      user.picture === "" ? (
        <Avatar icon={<UserOutlined />} />
      ) : (
        <Avatar src={user.picture} />
      );

    mobileAccountLinks = (
      <Fragment>
        <Space>
          {userPicture} <span className="mobile-username">{username}</span>
        </Space>
        {user.email_verified
          ? NoCapManifest.roles[`${role}`].topNavMenuItems.map((item) => {
              return (
                <NavLink key={item + item.path} to={item.path}>
                  {item.name}
                </NavLink>
              );
            })
          : null}
        <NavLink to="/" onClick={handleLogout}>
          Log&nbsp;out
        </NavLink>
      </Fragment>
    );
  }

  const menuIcon = (
    <Button id="hamburger" onClick={() => setDrawer(true)}>
      <div className="line" />
      <div className="line" />
      <div className="line" />
    </Button>
  );
  return (
    <Row align="middle" justify="center" style={{ marginTop: "-50px" }}>
      <Col xs={{ span: 20, order: 1 }} lg={{ span: 6, order: 1 }} align="left">
        <Link to="/">
          <img
            src="https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png"
            alt="NoCap Logo"
            style={{
              width: "250px",
            }}
          />
        </Link>
      </Col>
      <Col
        className="search-bar"
        xs={{ span: 24, order: 3 }}
        lg={{ span: 6, order: 3 }}
      >
        <SearchBar />
      </Col>
      <Col xs={{ span: 4, order: 2 }} lg={{ span: 0, order: 2 }} align="center">
        {menuIcon}
      </Col>
      <Drawer
        placement="right"
        closable={true}
        onClose={() => setDrawer(false)}
        visible={drawer}
        width={200}
        drawerStyle={{ alignItems: "center", justifyContent: "center" }}
      >
        <Space size="large" direction="vertical" align="center">
          {mobileAccountLinks}
          <Divider />
          {mobileMenuLinks}
        </Space>
      </Drawer>
      <Col xs={0} sm={0} md={0} lg={{ span: 12, order: 3 }} align="center">
        <Space size="large">
          {menuLinks} {accountLinks}
        </Space>
      </Col>
    </Row>
  );
};

export default TopNavBar;
