import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  message,
  Modal,
  InputNumber, 
  Select
} from "antd";
import { EnvironmentOutlined, PhoneOutlined, } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import SingleImageUploader from "../../shared/imageUploaders/singleImage/SingleImageUploader";
import axios from "axios";


// Components
import PoliticalParty from "./components/PoliticalParty";
import Religion from "./components/Religion";
import Position from "./components/Position";
import OfficeSelections from "./components/office/OfficeSelections";
import OfficeSelectionReformed from "./components/office/OfficeSelectionReformed";
import Vote from "./components/Vote";
import Contribution from "./components/Contribution";
import Committee from "./components/Committee";
import FamilyNew from "./components/FamilyNew";
import Education from "./components/Education";
import SocialMedia from "./components/SocialMedia";
import OfficeContacts from "./components/OfficeContacts";
import CustomInput from "../../../components/shared/onboarding/CustomInput";
import ProfessionalExperience from "./components/ProfessionalExperience";


// Google Place Autocomplete API -- START
let cityAutoComplete;
let addressAutoComplete;

function handleScriptLoad(
  setCityQuery,
  cityId,
  setOfficeAddressQuery,
  addressId
) {
  cityAutoComplete = new window.google.maps.places.Autocomplete(cityId, {
    types: ["(cities)"],
    componentRestrictions: { country: "us" },
  });
  cityAutoComplete.setFields(["address_components", "formatted_address"]);
  cityAutoComplete.addListener("place_changed", () =>
    handleCityPlaceSelect(setCityQuery)
  );

  addressAutoComplete = new window.google.maps.places.Autocomplete(addressId, {
    types: ["address"],
    componentRestrictions: { country: "us" },
  });
  addressAutoComplete.setFields(["address_components", "formatted_address"]);
  addressAutoComplete.addListener("place_changed", () =>
    handleAddressPlaceSelect(setOfficeAddressQuery)
  );
}

async function handleCityPlaceSelect(updateQuery) {
  const cityObject = cityAutoComplete.getPlace();
  const query = cityObject.formatted_address;
  updateQuery(query);
}

async function handleAddressPlaceSelect(updateQuery) {
  const addressObject = addressAutoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
}
// Google Place Autocomplete API -- END

const CandidateForm = (props) => {
  // Profile Picture
  const [logoUrl, setLogoUrl] = useState("");

  // Home City & Office Address
  const [cityQuery, setCityQuery] = useState("");
  const [officeAddressQuery, setOfficeAddressQuery] = useState("");

  useEffect(() => {
    handleScriptLoad(
      setCityQuery,
      document.getElementById("city-place-autocomplete"),
      setOfficeAddressQuery,
      document.getElementById("address-place-autocomplete")
    );
  }, []);

  // Office Selection
  const [form] = Form.useForm();
  const [divisionIds, setDivisionIds] = useState({
    incumbent: undefined,
    ballot: undefined,
  });

  // need to add division id

  // Birthdate
  const dateFormatList = ["MM/DD/YYYY", "MM/DD/YY"];


  //Profile Type

  const[profileType, setProfileType] = useState("");

  


  console.log(profileType)






  //Navigation - Cancel Button
  const history = useHistory();
  const backToAccountTypesOptions = () => {
    let path = `/profile-start`;
    history.push(path);
  };

  //loading
  const [loading, setLoading] = useState(false);

  // Submission
  const onFinish = (values) => {
    // if (values.birthdate !== undefined) {
    //   values.birthdate = values.birthdate.format("YYYY-MM-DD");
    // }
    if (officeAddressQuery) {
      values.address = officeAddressQuery;
    }
    if (cityQuery) {
      values.homeCity = cityQuery;
    }

    const data = {
      bio: {
        rating: {
          total: 0,
          stars: 0,
        },
        profileImage: logoUrl,
        name: values.name,
        social: {
          website: values.website,
          facebook: values.facebook,
          twitter: values.twitter,
          instagram: values.instagram,
          youtube: values.youtube,
          podcasts: values.podcasts,
        },
        general: {
          comments: [],
        },
        office: {
          // ballot: {
          //   division: divisionIds.ballot,
          //   state: values.ballotState,
          //   divisionName: values.ballotDivision,
          //   title: values.ballotOffice,
          // },
          // incumbent: {
          //   division: divisionIds.incumbent,
          //   state: values.incumbentState,
          //   divisionName: values.incumbentDivision,
          //   title: values.incumbentOffice,
          // },
          party: values.politicalParty,
          profileType: values.profileType
        },
        contact: {
          phone: values.phone,
          fax: values.fax,
          address: values.address,
          email: values.email,
        },
        personal: {
          birthdate: values.birthdate,
          contact: values.contact,
          homeCity: values.homeCity,
          family: {
            spouses: [],
            // childrens: values.childrens,
            father: values.father,
            mother: values.mother,
          },
          education: {
            college: values.college,
            highschool: values.highschool,
          },
          religion: values.religion,
          comments: [],
        },
        // political: {
        //   experience: values.politicalExperience,
        //   comments: [],
        // },
        // professional: {
        //   experience: values.careerExperience,
        //   comments: [],
        // },
        other: {
          experience: values.otherMembership,
          comments: [],
        },
      },
      positions: [],
      questions: [],
      legislations: [],
      contributions: [],
      committees: [],
      officeSelections: [],
      professionalExperiences: [],
      status: "approve",
      archived: false,
    };

    if (values.positions[0]) {
      values.positions.map((position) => {
        return data.positions.push({
          topic: position.category,
          position: position.detail,
          comments: [],
        });
      });
    }

    if (values.legislations[0]) {
      values.legislations.map((legislation) => {
        return data.legislations.push({
          bill: legislation.bill,
          billName: legislation.billName,
          sponsor: legislation.sponsor,
          summary: legislation.summary,
          vote: legislation.vote
        });
      });
    }

    if (values.contributions[0]) {
      values.contributions.map((contribution) => {
        return data.contributions.push({
          industry: contribution.industry,
          name: contribution.name,
          amount: contribution.amount,
        });
      });
    }

    if (values.committees[0]) {
      values.committees.map((committee) => {
        return data.committees.push({
          date: committee.committeeDate,
          description: committee.description
        });
      });
    }

    if (values.officeSelections[0]){
      values.officeSelections.map((officeSelection) => {
        return data.officeSelections.push({
          politicalDivision: officeSelection.politicalDivision, 
          title: officeSelection.title,
          position: officeSelection.position,
          district: officeSelection.district,
          state: officeSelection.state,
          city: officeSelection.division,
          office: officeSelection.office,
          term: officeSelection.term,
        })

      })
    }

    if(values.professionalExperiences !== undefined){
      values.professionalExperiences.map((professionalExperience) => {
        return data.professionalExperiences.push({
          title: professionalExperience.title,
          startDate: professionalExperience.startDate,
          endDate: professionalExperience.endDate
        })
      })
    }

    if(values.spouses !== undefined) {
      if (values.spouses[0]) {
      values.spouses.map((spouse) => {
        return data.bio.personal.family.spouses.push({
          Name: spouse.spouse,
          childrens: spouse.noOfChildren
        })
      })
    }
    }

    let candidate_id;
    console.log(data);
    setLoading(true);
    return axios
      .post(`${config.CANDIDATES}/candidates/insert`, JSON.stringify(data), {
        headers: {
          token: localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (JSON.parse(localStorage.getItem("user")).role !== "admin") {
            candidate_id = response.data.insertedId;
            const doc = JSON.stringify({
              role: "candidate",
              nickname: values.name,
              picture:
                logoUrl === ""
                  ? "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png"
                  : logoUrl,
              candidateId: candidate_id,
            });
            return axios
              .patch(
                `${config.USERS}/users`,
                {},
                {
                  headers: {
                    token: localStorage.getItem("access_token"),
                    doc: encodeURIComponent(doc),
                  },
                }
              )
              .then((response) => {
                setLoading(false);
                localStorage.setItem("role", "candidate");
                Modal.success({
                  content: "Thank you for creating your account with NoCap. Profile is under review, we will get back to you in 72 hrs",
                });
                history.push("/");
              });
          } else {
            setLoading(false);
            Modal.success({
              content: "You have successfully added a candidate.",
            });
            history.push("/");
          }
        } else {
          message.error(
            "Something went wrong, make sure you are connected to the internet."
          );
        }
      });
  };

  return (
    <Form
      name="candidateForm"
      className="accountForm"
      onFinish={onFinish}
      initialValues={{
        positions: [""],
        legislations: [""],
        contributions: [""],
        committees: [""]
      }}
      layout="vertical"
      form={form}
    >
      <Row gutter={[12]}>
        <Col span={24} style={{ marginBottom: "150px" }}>
          <SingleImageUploader
            handleImageUpload={(url) => setLogoUrl(url)}
            imagePreviewUrl={logoUrl}
            typeKey={"user"}
            title="Upload Profile Image"
            layout={"logo"}
          />
        </Col>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter your full name",
              },
            ]}
          >
            <Input
              placeholder="enter full name"
              onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label={
              <span>
                Contact Information{" "}
                <span style={{ fontSize: 10, fontStyle: "italic" }}>
                  (not available to the public)
                </span>
              </span>
            }
            name="contact"
            rules={[
              {
                required: true,
                message: "Please enter your contact information",
              },
            ]}
          >
          <InputNumber
            suffix={<PhoneOutlined />}
            placeholder="enter primary number"
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
            controls={false}
            style={{width: "100%"}}
            addonAfter={<PhoneOutlined />}
          />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item label="Date of Birth" name="birthdate"  >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="select a date (mm/dd/yyyy)"
              format={dateFormatList}
              popupStyle={{ color: "black" }}
            />
          </Form.Item>
        </Col>


        <Col>

        <Form.Item label="Profile type" name="profileType" rules={[
          {
            required: true, 
            message: "Please select your profile type"

          }
        ]}>

          <Select 
            showSearch
            placeholder="select profile type"
            onChange={(value) => {setProfileType(value)}}
            options={[
              {
                value: "new candidate",
                label: "new candidate"
              },
              {
                value: "candidate",
                label: "incumbent"
              },
              {
                value: "elected official",
                label: "elected official"
              }
              
            ]}
          />
        </Form.Item>

      
        </Col>



        {/* {(profileType !== "")? 
          (<div>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Election Details" name="electionDetails"  rules={[
                  {
                    required: true,
                    message: "Please enter your date of birth",
                  },
                ]}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="select a date (dd/mm/yyyy)"
                  format={dateFormatList}
                  popupStyle={{ color: "black" }}
                />
              </Form.Item>
            </Col>

          </div>): null
        } */}




        <PoliticalParty />
        {/* <OfficeSelections
          form={form}
          type="ballot"
          setDivisionIds={setDivisionIds}
          divisionIds={divisionIds}
        />
        <OfficeSelections
          form={form}
          type="incumbent"
          setDivisionIds={setDivisionIds}
          divisionIds={divisionIds}
        /> */}

        <OfficeSelectionReformed
          form={form}
          type="incumbent"
          setDivisionIds={setDivisionIds}
          divisionIds={divisionIds}
        />

        <Col span={24}>
          <OfficeContacts
            setOfficeAddressQuery={
              setOfficeAddressQuery
            }
            officeAddressQuery={officeAddressQuery}
          />
        </Col>

        <Position />
        <Col span={24}>
          <Vote />
        </Col>
        <Col span={24}>
          <Contribution />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item label="Residence(s)">
            <Input
              suffix={<EnvironmentOutlined />}
              id="city-place-autocomplete"
              type="text"
              placeholder="search by city and state"
              onChange={(event) => setCityQuery(event.target.value)}
              value={cityQuery}
              onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
            />
          </Form.Item>
        </Col>
        <Religion />
        <Col span={12} />
        <Col span={24}>
          <FamilyNew />
        </Col>
        <Col span={24}>
          <Education />
        </Col>
        <Col span={24}>
          <ProfessionalExperience/>
        </Col>
        {/* <Col span={24}>
          <Form.Item label="Profession" name="politicalExperience">
            <Input.TextArea autoSize allowClear placeholder="enter previous office(s) held" />
          </Form.Item>
        </Col> */}
        {/* <Col span={24}>
          <Form.Item label="Professional Experience" name="careerExperience">
            <Input.TextArea autoSize allowClear placeholder="enter name" />
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <Committee/>
        </Col>
        <Col span={24}>
          <SocialMedia SocialMedia />
        </Col>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Form.Item>
            <Button
              className="btn-bordered"
              onClick={backToAccountTypesOptions}
              style={{ margin: "0px 5px", width: "85px" }}
              type="text"
            >
              Cancel
            </Button>
            <Button
              className="btn-solid"
              htmlType="submit"
              style={{ margin: "0px 5px", width: "85px" }}
              type="text"
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CandidateForm;
