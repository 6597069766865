// Libraries
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// Components
import AdHelper from "../shared/ad/AdHelper";
import CategoryTags from "./CategoryTags";
import CandidateSearchAndTags from "../candidate/CandidateSearchAndTags";
import SingleImageUploader from "../shared/imageUploaders/singleImage/SingleImageUploader";
import QuillEditor from "./QuillEditor";
import FactCheck from "./FactCheck";

// Ant Design Components
import { Button, Row, Col, Affix, BackTop, Form, Input, message, Select } from "antd";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  InboxOutlined,
  SaveOutlined,
} from "@ant-design/icons";

// Helpers & Config
import NoCapManifest from "../../nocap.json";
import config from "../../config";
import { useBreakpoint } from "../../hooks/useWindowSize";
import { CandidateContext } from "../../providers/CandidateContext";
import { NewsContext } from "../../providers/NewsContext";
import DynamicCityStateDistrict from "../forms/candidateForm/components/DynamicCityStateDistrict"

const EditNewsPost = (props) => {
  // Blog Id
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  // States from context provider
  const { candidateTags, setCandidateTags } = useContext(CandidateContext);
  const { factCheckTags, setFactCheckTags } = useContext(NewsContext);

  useEffect(() => {
    setCandidateTags(props.data.candidates || []);
    setFactCheckTags(props.data.factCheck || []);
    loadInitialStateandDistrict();
    // eslint-disable-next-line
  }, []);

  // Width from custom hook
  const { width, mobileView } = useBreakpoint();

  // Category tags states
  const [currentCategory, setCurrentCategory] = useState(
    props.data.category || ""
  );

  const [candidateData, setCandidateData] = useState({ candidates: [] });

  const loadInitialStateandDistrict = async() => {
    if (props.data.state) {
      form.setFieldsValue({state: props.data.state})
    }
    if (props.data.district) {
      form.setFieldsValue({district: props.data.district})
    }
  }


  const handleCandidateSearch = async (search) => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${config.CANDIDATES}/candidates/search?search-text=${search}&limit=100`
      );

      setCandidateData(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      message.error("Something is wrong when fetching data.");
    }
  };

  const handleClearCandidateSearch = () => {
    setCandidateData({ candidates: [] });
  };

  // Component States
  const [formData, setFormData] = useState({
    title: props.data.title || "",
    blogType: props.data.blogType || "",
    author: props.data.author || "",
    coverImage: props.data.coverImage || "",
    body: props.data.body || "",
    editorial: props.data.editorial || "",
  });

  const [archived, setArchived] = useState(props.data.archived || false);

  // Ant Design Title Form Field
  const [form] = Form.useForm();

  // Navigation and Buttons
  const history = useHistory();
  const backToAccountTypesOptions = () => {
    history.goBack();
  };

  const backButton = (
    <Button
      className="btn-bordered"
      onClick={backToAccountTypesOptions}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      {width > 600 ? "Back" : null}
    </Button>
  );

  const handleSaveNewsPost = async () => {
    setIsLoading(true);
    let reducedTags = candidateTags.map((tag) => {
      return tag._id;
    });
    const newsPost = {
      ...props.data,
      ...formData,
      category: currentCategory,
      candidates: reducedTags,
      factCheck: factCheckTags, 
      state: form.getFieldValue("state"),
      district: form.getFieldValue("district")
    };

    try {
      await axios.patch(`${config.BLOGS}/blogs/update/${id}`, newsPost, {
        headers: {
          token: localStorage.getItem("access_token"),
        },
      });
      message.success("Your blog post has been saved successfully!");
      setIsLoading(false);
      backToAccountTypesOptions();
    } catch (err) {
      console.log(err);
      message.warning("Your blog post wasn't saved‼️");
      setIsLoading(false);
    }
  };

  const saveButton = (
    <Button
      className="btn-solid"
      size="large"
      icon={<SaveOutlined />}
      disabled={isLoading}
      loading={isLoading}
      onClick={handleSaveNewsPost}
    >
      {mobileView ? "Save" : "Save Article"}
    </Button>
  );

  const handleArchiveNewsPost = async () => {
    // Set archive field to opposite of current boolean
    setIsLoading(!isLoading);
    const newsPost = {
      ...props.data,
      archived: !archived,
    };

    const json = JSON.stringify(newsPost);

    try {
      await axios.patch(
        `${config.BLOGS}/blogs/update/${id}`,
        newsPost,
        {
          cors: true,
          headers: {
            token: localStorage.getItem("access_token")
            // doc: encodeURIComponent(json),
          },
        }
      );
      setArchived(!archived);

      message.success(
        !archived
          ? "Article has been archived!"
          : "Article removed from archive!"
      );
      setIsLoading(!isLoading);
    } catch (err) {
      console.log(err);
      message.warning("Article archive status wasn't saved‼️");
      setIsLoading(!isLoading);
    }
  };

  const archiveButtonText = archived ? "Unarchive" : "Archive";

  const archiveButton = (
    <Button
      className="btn-warning"
      size="large"
      icon={<InboxOutlined />}
      onClick={handleArchiveNewsPost}
    >
      {mobileView ? archiveButtonText : archiveButtonText + " Article"}
    </Button>
  );

  const cancelButton = (
    <Button
      className="btn-bordered"
      onClick={backToAccountTypesOptions}
      icon={<CloseOutlined />}
      size="large"
      style={{ marginRight: "24px" }}
    >
      Cancel
    </Button>
  );

  // Role-based render
  const user = JSON.parse(localStorage.getItem("user")) || {};

  if (user.role !== "admin") {
    return message.warning(
      `Please sign in as an administrator to edit this article.`
    );
  }

  return (
    <div className="BlogPost">
      <div className="constrain">
        <header>
          <div className="header-buttons">
            {width > 600 && backButton}
            <div className="button-group">
              {archiveButton}
              {saveButton}
            </div>
          </div>
        </header>
        <Row>
          <Col xs={0} xl={3}>
            <Affix offsetTop={10}>
              <AdHelper page="news-post" position="left" />
            </Affix>
          </Col>
          <Col xs={24} xl={18}>
            <div className="news-post-new-form-container">
              <Form name="titleForm" form={form} layout="vertical">
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true, message: "Title is required!" }]}
                  initialValue={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                >
                  <Input />
                </Form.Item>
                <SingleImageUploader
                  handleImageUpload={(url) =>
                    setFormData({ ...formData, coverImage: url })
                  }
                  imagePreviewUrl={formData.coverImage}
                  typeKey={"newsCover"}
                  imageOptional={false}
                  layout={"newsCover"}
                />
                {props.data.videoUrl ? (
                  <div className="videoContainer">
                    <div className="videoWrapper">
                      <iframe
                        src={props.data.videoUrl}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="video"
                      />
                    </div>
                  </div>
                ) : null}
                <div className="meta">
                  <h3>
                    Published by{" "}
                    <span>
                      <span className="nickname">{props.data.author}</span>
                    </span>
                    <br />
                    {props.data.created_at?
                    (<span className="date">
                      {moment(props.data.created_at).format("MMMM DD, YYYY")}
                    </span>): null}
                  </h3>
                </div>

                <Form.Item 
                    label="Paid Blog" 
                    name="blogType"
                    rules={[
                      {
                        required: false,
                        message: "Please select your blog type"
                      }

                    ]}
                  >
                    <Select
                      showSearch
                      placeholder = "select your blog type"
                      onChange = {(value) => setFormData({...formData, blogType: value })}
                      options = {[
                        {
                          value: "yes",
                          label: "yes"
                        }, 
                        {
                          value: "no", 
                          label: "no"
                        }
                      ]}
                    />
                </Form.Item>

                <Form.Item
                  name="author"
                  label="Author"
                  initialValue={formData.author}
                  onChange={(e) => setFormData({ ...formData, author: e.target.value })}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <DynamicCityStateDistrict 
                    form={form}
                  />
                </Form.Item>

                <CategoryTags
                  categories={NoCapManifest.news.newsCategories}
                  width="95%"
                  value={currentCategory}
                  onChange={setCurrentCategory}
                />
                <CandidateSearchAndTags
                  candidateData={candidateData}
                  onSearch={(value) => handleCandidateSearch(value)}
                  onClick={handleClearCandidateSearch}
                  loading={isLoading}
                />
                <FactCheck/>
                <QuillEditor
                  value={formData.body}
                  onChange={(html) => setFormData({ ...formData, body: html })}
                />
                <Form.Item
                  name="editorial"
                  label="Editorial/NoCap Commentary"
                  onChange={(e) =>
                    setFormData({ ...formData, editorial: e.target.value })
                  }
                >
                  <Input.TextArea />
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={0} xl={3}>
            <Affix offsetTop={10}>
              <AdHelper page="news-post" position="right" />
            </Affix>
          </Col>
          <Col xs={12} xl={0}>
            <AdHelper page="news-post" position="left" />
          </Col>
          <Col xs={12} xl={0}>
            <AdHelper page="news-post" position="right" />
          </Col>
        </Row>
        <div className="footer">
          {width > 600 && archiveButton}
          <div className="button-group">
            {cancelButton}
            {saveButton}
          </div>
        </div>
      </div>
      <BackTop />
    </div>
  );
};

export default EditNewsPost;
