import React, { useState } from "react";
import axios from "axios";
import config from "../../../../config";
import Add from "./office/Add";
import { capitalizeFirstLetterOfEachWord } from "../../../../helpers";
import NoCapManifest from "../../../../nocap.json";
import { Form, Input, Col, Button, Select, message } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

let stateIndex = 1;
let divisionIndex = 1;
let districtIndex = 1;


const DynamicCityStateDistrict = (props, formValues) => {
  const [inputState, setInputState] = useState({
    state: "",
    division: "",
    district: ""
  });
  const [states, setStates] = useState(
    NoCapManifest.candidateInfoForm.stateData
  );
  
 
  const [divisions, setDivisions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [divisionPhrase, setDivisionPhrase] = useState("select city");
  const [stateSelections, setStateSelections] = useState(null);
  const [divisionSelections, setDivisionSelections] = useState(null)



  //PoliticalDivsion and Title Dropdown

  const [districts, setDistricts] = useState(NoCapManifest.candidateInfoForm.districts);
  const [districtSelections, setDistrictSelections] = useState(null);




  const handleStateChange = async (value) => {
    // reset all the divisions and offices related states
    setDivisions([]);
    divisionIndex = 1;
    // console.log(props.form)
    // console.log(props.form.getFieldValue(`TestState`))
    // // console.log(props.form.getFieldsValue(true).officeSelections[0].state);
    // console.log(props.form.getFieldsValue(true));
    // console.log((props.form.getFieldValue(`officeSelections`)).state)
    // console.log(value);

    // reset selected option
    

    // console.log(props.form.getFieldsValue(true))
    // console.log(props.form.getFieldsValue(true).officeSelections[index].state)
    // console.log(props.form.getFieldValue("officeSelections"))
    // setStateSelections(props.form.getFieldValue("officeSelections")[index].state)

    // console.log(props.form.getFieldValue("state"))
    // console.log(props.form.getFieldValue("division"))
    // console.log(props.form.getFieldValue("distict"))  

    // props.form.getFieldValue(`${props.type}State`)
    // props.form.getFieldValue(`${props.type}District`)
    // props.form.getFieldValue(`${props.type}Division`)

    // props.setDivisionIds({
    //   ...props.divisionIds
    // });

    // disabled office and division selection and show 'loading ...' when state is fetching data
    setLoading(true);
    setDivisionPhrase("Loading ...");

    // fetch the division and office list if the state is not manually entered
    if (!states[value].startsWith("other")) {
      const divisions = await axios.get(
        `${config.CANDIDATES}/candidates/get-dvisions-list`,
        {
          cors: true,
          responseType: "json",
          headers: {
            state: NoCapManifest.candidateInfoForm.stateData[value],
          },
        }
      );
      console.log(divisions)
      if (divisions.data.length > 0) {
        setDivisions(divisions.data);
        setDivisionPhrase("select city");
      } else {
        setDivisionPhrase("add a division");
        
      }
    } else {
      setDivisionPhrase("add a city");
     
    }
    setLoading(false);
  };

  const handleDivisionChange = async (value, e) => {
    setLoading(true);

    setDivisionSelections(value)

    props.setDivisionIds({
      ...props.divisionIds,
    });
    let result = await divisions.filter((division) => {
      return division.name === value;
    });
    setLoading(false);
  };

  const addState = (e) => {
    e.preventDefault();
    let i = stateIndex++;
    let addedState = inputState.state
      ? capitalizeFirstLetterOfEachWord(inputState.state)
      : `New State ${i}`;
    setInputState({ ...inputState, state: "" });
    setStates({ ...states, [`${addedState}`]: `other ${i}` });
  };

  const addDivision = (e) => {
    e.preventDefault();
    let i = divisionIndex++;
    let addedDivision = inputState.division
      ? capitalizeFirstLetterOfEachWord(inputState.division)
      : `New Division ${i}`;
    setInputState({ ...inputState, division: "" });
    setDivisions([
      ...divisions,
      {
        divisonId: `other ${i}`,
        name: addedDivision,
        _id: `other ${i}`,
      },
    ]);
  };


  const handleChangeDistrict = async(value) => {
    setDistrictSelections(value)
    
    console.log(districtSelections)
  }

  const addDistrict = (e) => {
    e.preventDefault();
    let i = districtIndex++;
    let addedDistrict = inputState.district
      ? capitalizeFirstLetterOfEachWord(inputState.district)
      : `New Area ${i}`;

    if(districts.includes(addedDistrict)) {
      console.log("District already exists")
      message.error("District already exists")
      setInputState({...inputState, district: ""})
    } else {
    setInputState({ ...inputState, district: "" });
    setDistricts([ ...districts, addedDistrict]);
    }

  }

  

    return (

      //State
      
      
      <div>
       
        <Form.Item
          label="District"
          labelCol={{ span: 12 }}
          name={`district`}
          rules={[
            {
              required: false,
              message: `Please select your district`,
            },
          ]}
        >
        <Select
          showSearch
          placeholder="Please select your district"
          onChange={(value) => handleChangeDistrict(value)}
          dropdownRender={(menu) => (
            <Add
              menu={menu}
              setInputState={setInputState}
              inputState={inputState}
              placeholder = "please enter district"
              type="district"
              add={addDistrict}
            />
          )}
        >
          {districts.map((district, i) => (
            <Option key={"district" + i} value={district}>
              {district}
            </Option>
          ))}
        </Select>
        </Form.Item>
        


       
        <Form.Item
          label="State"
          labelCol={{ span: 12 }}
          name= {"state"}
          
          rules={[
            {
              required: false,
              message: `please select your state`,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="select state"
            onChange={(value) => handleStateChange(value)}
            dropdownRender={(menu) => (
              <Add
                menu={menu}
                setInputState={setInputState}
                inputState={inputState}
                type="state"
                placeholder = "please enter state"
                add={addState}
              />
            )}
          >
            {Object.keys(states).map((state) => (
              <Option key={states[state]} value={state}>
                {state}
              </Option>
            ))}
          </Select>
        </Form.Item>
        

        {/* <Form.Item
          label="City"
          name={`${props.type}Division`}
          
          
        >
          <Select
            showSearch
            placeholder={divisionPhrase}
            loading={loading}
            onChange={(value, e) => handleDivisionChange(value, e)}
            disabled={
              !(stateSelections) || loading
            }
            dropdownRender={(menu) => (
              <Add
                menu={menu}
                setInputState={setInputState}
                inputState={inputState}
                type="division"
                placeholder = "please enter city"
                add={addDivision}
              />
            )}
          >
            {divisions.map((division) => (
              <Option key={division.divisonId} value={division.name}>
                {division.name}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

      </div>
    );

};

export default DynamicCityStateDistrict;
