// Libraries
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// ComponentWillMount and ComponentWillReceiveProps UI Warnings are related to react-stack-grid npm packages

// Components
import AdHelper from "../shared/ad/AdHelper";
import Careers from "../shared/careers/CareerTab";
import CategoryTags from "../news/CategoryTags";
import Loading from "../Loading";
import NewsCard from "../news/NewsCard";
import AdInRow from "../ad/AdInRow";

// Ant Design Components
import { Empty, message, BackTop, Button, Row, Col, Select} from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Helpers & Config
import NoCapManifest from "../../nocap.json";
import config from "../../config";
import useNewsData from "../../hooks/useNewsData";

import DynamicCityStateDistrict from "../forms/candidateForm/components/DynamicCityStateDistrict"

import Add from "../forms/candidateForm/components/office/Add";
import { capitalizeFirstLetterOfEachWord } from "../../helpers";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const { Option } = Select;
let districtIndex = 1;

const NewsPage = (props) => {
  const [currentCategory, setCurrentCategory] = useState("");
  const [districts, setDistricts] = useState(NoCapManifest.candidateInfoForm.districts);
  const [districtSelections, setDistrictSelections] = useState(null);
  const [inputState, setInputState] = useState({
    state: "",
    division: "",
    district: ""
  });

  // Retrieve window and news data/states using custom hook

  const { get, data, error, loading } = useNewsData(
    `${config.BLOGS}/blogs/all`
  );

  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);
console.log(data);

  const handleChangeDistrict = async(value) => {
    setDistrictSelections(value)
    
    console.log(districtSelections)
  }

  const addDistrict = (e) => {
    e.preventDefault();
    let i = districtIndex++;
    let addedDistrict = inputState.district
      ? capitalizeFirstLetterOfEachWord(inputState.district)
      : `New Area ${i}`;

    if(districts.includes(addedDistrict)) {
      console.log("District already exists")
      message.error("District already exists")
      setInputState({...inputState, district: ""})
    } else {
    setInputState({ ...inputState, district: "" });
    setDistricts([ ...districts, addedDistrict]);
    }

  }

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information"
    );

  let result = null;
  if (loading) {
    result = <Loading />;
  } else if (data && data.length > 0) {
    let newsCount = data.length;
    let careerPosts = [];

    const user = JSON.parse(localStorage.getItem("user")) || {};

    let blogItems = data.map((post, idx) => {
      if (post.archived !== true && post.category === currentCategory) {
        return (
          <AdInRow count={idx} key={post["_id"]}>
            <Col
              sm={24}
              md={12}
              lg={8}
              xl={6}
              style={{
                padding: "0 1em",
                marginBottom: "2em",
              }}
            >
              <NewsCard
                post={post}
                pathname={`/news/${post["_id"]}`}
                getData={get}
              />
            </Col>
          </AdInRow>
        );
      } else {
        return "";
      }
    });

    // const filterBlogs =
    // serviceArray.services &&
    // serviceArray.services.filter((s) =>
    //   s.descriptors.categories.includes(currentServiceCategory)
    // );


    result = (
      <>
        <div className="news-cta-container">
          {/* Admin condition: only show btn if admin */}
          {user.role === "admin" && (
            <Link to="/news/new" className="news-cta--btn-container">
              <Button
                className="btn-bordered"
                icon={<PlusOutlined />}
                size="large"
              >
                Add News Post
              </Button>
            </Link>
          )}
          {/* Admin condition ends */}
        </div>

        {newsCount !== 0 ? (
          <Row className="news-row">{blogItems}</Row>
        ) : currentCategory === "Careers" ? (
          <Careers careerPosts={careerPosts} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}

        

        <BackTop />
      </>
    );
  } else {
    result = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return (
    <div>
      <div className="news-ads-container">
        <AdHelper page="news" />
      </div>
      <br/>
      <div className="news-cta-container">
        <CategoryTags
              categories={NoCapManifest.news.newsCategories}
              value={currentCategory}
              onChange={setCurrentCategory}
        />
      </div>
      <br/>
        <div>
            <h3>Filters:</h3>
            <br/>
            <div>
              <Select
                  showSearch
                  placeholder="Please select your district"
                  onChange={(value) => handleChangeDistrict(value)}
                  dropdownRender={(menu) => (
                    <Add
                      menu={menu}
                      setInputState={setInputState}
                      inputState={inputState}
                      placeholder = "please enter district"
                      type="district"
                      add={addDistrict}
                    />
                  )}
              >
                  {districts.map((district, i) => (
                    <Option key={"district" + i} value={district}>
                      {district}
                    </Option>
                  ))}
              </Select>
            </div>
            <div>

            </div>
       </div>
      {result}
      <br />
      <div className="news-ads-container">
        <AdHelper page="news" />
      </div>
    </div>
  );
};

export default NewsPage;
