// Libraries
import React, { useContext, useEffect, useState } from "react";
import { Tag } from "antd";
import axios from "axios";

// Components
import CandidatesSection from "../search/CandidatesSection";
import { NewsContext } from "../../providers/NewsContext";
import { CandidateContext } from "../../providers/CandidateContext";
import FactCheckTagList from "./FactCheckTagList";
import config from "../../config";
import NoCapManifest from "../../nocap.json";
import Add from "../forms/candidateForm/components/office/Add"

// Ant Design Components
import { Button, Form, Input,Select, message } from "antd";

const { Option } = Select;

let newsPageIndex = 1;

const FactCheck = ({
  label,
  loading
}) => {
  const { factCheckTags, setFactCheckTags } = useContext(NewsContext);
  const { candidateTags, setCandidateTags } = useContext(CandidateContext);
  const [newsPages, setNewsPages] = useState(NoCapManifest.news.newsPages)
  const [factCheckLink, setFactCheckLink] = useState(null);
  const [factCheckImageLink, setFactCheckImageLink] = useState(null);
  const [newsPageSelection, setNewsPageSelection] = useState(null);
  const [inputState, setInputState] = useState({
    newsPage:""
  });


  useEffect(() => {
    console.log(factCheckTags);
    console.log(candidateTags);
    console.log(newsPages);
  }, [factCheckTags]); // eslint-disable-line

  // async function getElectionsData() {
  //   if (candidateTags?.length > 0) {
  //     let headers = {
  //       ids: JSON.stringify(candidateTags),
  //       token: localStorage.getItem("access_token"),
  //     };
  //     const { data: candidateResultData } = await axios.get(
  //       `${config.CANDIDATES}/candidates/find-by-ids`,
  //       { headers }
  //     );
  //
  //     setCandidateTags(candidateResultData);
  //   }
  // }

  function removeFactCheck(link) {
    // const newTags = candidateTags;
    setFactCheckTags((prev) => prev.filter((v) => v.link !== link));
  }

  function handleSettingTag() {
    let tag = {link: factCheckLink, imageLink:factCheckImageLink, newsPage:newsPageSelection}
    setFactCheckTags([...factCheckTags, tag])
    setFactCheckLink("");
    setFactCheckImageLink("");
    setNewsPageSelection("");


    console.log(tag)
  }

  const handleFactCheckOnChange = (e) => {
    e.preventDefault();
    setFactCheckLink(e.target.value);
  }

  const handleChangeNewsPage = (value) => {
    
    setNewsPageSelection(value);

  }

  const addNewsPage = (e) => {
    e.preventDefault();
    let i = newsPageIndex++;
    let addedNewsPage = inputState.newsPage
      ? inputState.newsPage
      : `New News Page ${i}`;

    if(newsPages.includes(addedNewsPage)) {
      console.log("Page already exists. Please select from the list")
      message.error("Page already exists. Please select from the list")
      setInputState({...inputState, newsPage: ""})
    } else {
    setInputState({ ...inputState, newsPage: "" });
    setNewsPages([ ...newsPages, addedNewsPage]);
    }

  }

  return (
    <>
      <Form.Item name="factCheck">

        {factCheckTags.length > 0  &&
          factCheckTags.map((factCheck) => (
            <Tag
              key={factCheck.link}
              closable
              onClose={() => removeFactCheck(factCheck.link)}
              style={{ marginBottom: 8 }}
            >
              {factCheck?.link}
            </Tag>
          ))}

          <Form.Item name="FactCheck" label="FactCheck">
            <Input placeholder="news link" onChange={(e)=> setFactCheckLink(e.target.value)} value={factCheckLink} />
            <br/>
            <br/>
            <Input placeholder = "picture link" onChange= {(e)=> setFactCheckImageLink(e.target.value)} value= {factCheckImageLink} />
            <br/>
            <br/>
            <Form.Item
              labelCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: `Please select your news outlet`,
                },
              ]}
            >
              <Select
                loading={loading}
                onChange={(value) => handleChangeNewsPage(value)}
                placeholder = "news page"
                dropdownRender={(menu) => (
                  <Add
                    menu={menu}
                    placeholder="please enter news source"
                    setInputState={setInputState}
                    inputState={inputState}
                    type="newsPage"
                    add={addNewsPage}
                  />
                )}
              >
                {newsPages.map((newsPage, i) => (
                  <Option key={"newsPage" + i} value={newsPage}>
                    {newsPage}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            
          </Form.Item>


          


          {(factCheckLink != null && factCheckImageLink != null)?
            ( <Button
              className="btn-bordered"
              style={{ margin: "1em 0" }}
              onClick={()=>
                handleSettingTag()
              }
            >
              Add
            </Button>
            ):null

          }
      </Form.Item>
    </>
  );
};

export default FactCheck;
